import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import {KeyboardArrowDown} from '@material-ui/icons';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import Accordion from '../Shared/Components/accordion/Accordion';
import {
  getAssessments,
  initiateAssessment,
  getOrgDetails
} from '../Shared/Helpers/api.helper';
import styles from './screening.module.scss';
import leftBg from '../../assets/self_assessment_left.png';
import rightBg from '../../assets/self_assessment_right.png';
import BackdropLoader from '../Shared/Components/backdrop-loader/BackdropLoader';
import Nav from '../Shared/Components/nav/Nav';

export default function Screening() {
  const {id} = useParams();
  const [state, setState] = useState({email: '', assessment: ''});
  const [error, setError] = useState({email: null, assessment: null});
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assessments, setAssessments] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});

  useEffect(() => {
    getAssessments(id)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setAssessments(res.assessments);
      });

    getOrgDetails(id)
      .then((res) => res.data())
      .then((res) => {
        if (res) setOrgDetails(res);
      });
  }, [id]);

  const handleChange = useCallback((event) => {
    const {name, value} = event.target;
    setState((s) => ({
      ...s,
      [name]: value
    }));
    // eslint-disable-next-line no-control-regex
    const regex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    switch (name) {
      case 'assessment':
        if (value) {
          setError((e) => ({
            ...e,
            assessment: null
          }));
        } else {
          setError((e) => ({
            ...e,
            assessment: 'Select an assessment'
          }));
        }
        break;
      case 'email':
        if (value.length && !!value.trim().toLowerCase().match(regex)) {
          setError((e) => ({
            ...e,
            email: null
          }));
        } else {
          setError((e) => ({
            ...e,
            email: 'Invalid email'
          }));
        }
        break;
      default:
    }
  }, []);

  const validation = useMemo(
    () =>
      !error.assessment && !error.email && !!state.assessment && !!state.email,
    [state, error]
  );

  const startAssessment = useCallback(() => {
    if (validation) {
      setLoading(true);
      initiateAssessment({
        assessmentId: state.assessment,
        email: state.email,
        orgId: id
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'fail') {
            if (res.message === 'Email is not valid') {
              setError((e) => ({
                ...e,
                email: res.message
              }));
            } else {
              setApiError(res.message);
            }
          } else {
            window.location = res.assessmentLink;
            setApiError(null);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setApiError(err.message);
        });
    }
  }, [id, state, validation]);

  return (
    <>
      <Nav orgLogo={orgDetails.icon} />
      <div className={styles.screening}>
        {loading && <BackdropLoader />}
        <div className={styles.form}>
          <div className={styles.curious}>
            Feeling unlike yourself? Wondering if something is wrong? Curious if
            you should connect with a counsellor?
          </div>
          <div className={styles.subtitle}>Take a screening</div>
          <div className={styles.fields}>
            <TextField
              className={styles.textfield}
              size='small'
              id='email'
              name='email'
              label='Organization email'
              variant='outlined'
              type='email'
              autoComplete='true'
              required
              fullWidth
              value={state.email}
              onChange={handleChange}
              helperText={error.email}
              error={!!error.email}
            />
            <FormControl
              className={styles.textfield}
              size='small'
              variant='outlined'
              fullWidth
            >
              <InputLabel htmlFor={styles.assessment_selection}>
                Select Assessment
              </InputLabel>
              <Select
                label='Select assessment'
                name='assessment'
                id='assessment'
                onChange={handleChange}
                value={state.assessment}
                onClose={() => {
                  if (state.assessment) {
                    setError((e) => ({
                      ...e,
                      assessment: null
                    }));
                  } else {
                    setError((e) => ({
                      ...e,
                      assessment: 'Select an assessment'
                    }));
                  }
                }}
                error={!!error.assessment}
              >
                {assessments.map(({id: assessmentId, title, shortTitle}) => (
                  <MenuItem key={assessmentId} value={assessmentId}>
                    {window.innerWidth < 600 ? shortTitle : title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!error.assessment}>
                {error.assessment}
              </FormHelperText>
            </FormControl>
            <Button
              className={styles.button}
              variant='contained'
              color='primary'
              fullWidth
              disabled={!validation}
              onClick={startAssessment}
            >
              Start
            </Button>
            <p className={styles.error}>{apiError}</p>
            {/* <p className={styles.disclaimer}>
            By clicking “Start”, you agree to PsyPack’s Terms of Use and
            acknowledge you have read the Privacy Policy
          </p> */}
          </div>
        </div>
        <div
          className={styles.img_container}
          style={{
            background: `url(${leftBg}) left bottom no-repeat, url(${rightBg}) right bottom no-repeat`
          }}
        />
        <div className={styles.assessments}>
          <div className={styles.health}>
            Know more about mental health assessments
          </div>
          {!!assessments.length && (
            <div className={styles.accordions}>
              {assessments.map(
                ({id: assessmentId, title, shortTitle, definition}) => (
                  <div key={assessmentId} className={styles.item}>
                    <Accordion
                      opened={false}
                      rotation='180'
                      Arrow={KeyboardArrowDown}
                    >
                      <div className={styles.title}>
                        {window.innerWidth > 600 ? title : shortTitle}
                      </div>
                      <div className={styles.body}>{definition}</div>
                    </Accordion>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
