import {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import clsx from 'clsx';

import {getUserAssessmentStatus} from '../../Shared/Helpers/api.helper';
import {PSYPACK_URL} from '../../Shared/Helpers/constants';
import style from './StatusLoader.module.scss';

const StatusLoader = ({orgId, userAssessmentId}) => {
  const [count, setCount] = useState(0);
  const [noReport, setNoReport] = useState(false);
  let step = 0;
  const [status, setStatus] = useState('Preparing report...');
  const [reportId, setReportId] = useState(null);
  const [tempReportUrl, setTempReportUrl] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (reportId || tempReportUrl) {
        clearInterval(interval);
        setCount(5);
        return;
      }
      if (step === 1) {
        setStatus('Calculating score...');
      } else if (step === 2) {
        setStatus('Drawing graphs...');
      } else if (step === 3) {
        setStatus('Double checking...');
      } else if (step === 4) {
        setStatus('Completing...');
      } else if (step !== 0) {
        clearInterval(interval);
      }
      step++;
      setCount(step);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [reportId, tempReportUrl]);

  useEffect(() => {
    setTimeout(() => {
      getUserAssessmentStatus(orgId, userAssessmentId)
        .then((res) => res.json())
        .then((res) => {
          if (res.reportUrl) {
            setTempReportUrl(res.reportUrl);
            setStatus('Completed');
          } else if (res.reportId) {
            setReportId(res.reportId);
            setStatus('Completed');
          } else {
            setNoReport(true);
            setStatus(
              `It looks like, we will need more time. But you don't have to wait. We will email you the report as soon as it's ready`
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setNoReport(true);
          setStatus(
            `It looks like, we will need more time. But you don't have to wait. We will email you the report as soon as it's ready`
          );
        });
    }, 12000);
  }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.loader_container}>
        <div className={clsx(style.loader, noReport && style.warning)}>
          <div
            style={{
              width: `${reportId || tempReportUrl ? '100%' : 18 * count}%`
            }}
            className={style.progress}
          />
        </div>
        {status && <p className={style.status}>{status}</p>}
        {(reportId || tempReportUrl) && (
          <Button
            className={style.button}
            variant='contained'
            color='primary'
            fullWidth
            href={tempReportUrl || `${PSYPACK_URL}report/${reportId}`}
          >
            View report
          </Button>
        )}
      </div>
    </div>
  );
};

export default StatusLoader;
