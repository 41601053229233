import firebase from 'firebase/app';
import 'firebase/firestore';

let firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

if (process.env.REACT_APP_NODE_ENVIRONMENT == 'staging') {
  firebaseConfig = {
    apiKey: 'AIzaSyDvW2ma8aC69_gzfEGXpN-0RtCKt8mO-ck',
    authDomain: 'self-assessment-26db7.firebaseapp.com',
    projectId: 'self-assessment-26db7',
    storageBucket: 'self-assessment-26db7.appspot.com',
    messagingSenderId: '198365146890',
    appId: '1:198365146890:web:f8e83463d999f1063d14f3',
    measurementId: 'G-CT6Z9CYV8G'
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

export {firestore};
