import clsx from 'clsx';
import {useState} from 'react';
import styles from './accordion.module.scss';

const Accordion = ({children, Icon, Arrow, opened = false, tab, rotation}) => {
  const [open, setOpen] = useState(opened);
  const expandable = children.length === 2;
  return (
    <div
      className={clsx(
        styles.accordion_container,
        open && expandable && styles.accordion_item_opened
      )}
    >
      <div
        role='tab'
        tabIndex={tab}
        className={styles.accordion_item_line}
        onClick={expandable ? () => setOpen(!open) : null}
        onKeyDown={expandable ? () => setOpen(!open) : null}
      >
        <div className={styles.accordion_item_title}>
          {Icon && <Icon className={styles.accordion_item_icon} />}
          {expandable ? children[0] : children}
        </div>
        <div
          className={clsx(
            styles.accordion_item_ani,
            rotation && styles[`accordion_item_ani_${rotation}`]
          )}
        >
          {Arrow && <Arrow />}
        </div>
      </div>
      {expandable && (
        <div className={styles.accordion_item_inner}>
          <div className={styles.accordion_item_content}>{children[1]}</div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
