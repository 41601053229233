import styles from './logo.module.scss';

export default function Logo({imgSrc}) {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <div className={styles.psypack}>
          <img src={imgSrc} alt='PsyPack' />
        </div>
      </div>
    </div>
  );
}
