import style from './nav.module.scss';
import Logo from '../logo/Logo';
import psypackLogo from '../../../../assets/psypack.svg';
import ticktalktoLogo from '../../../../assets/logo-2.svg';

const Nav = ({orgLogo}) => (
  <div className={style.container}>
    <Logo imgSrc={psypackLogo} />
    <img className={style.org_logo} src={orgLogo} alt='Organisation' />
  </div>
);

export default Nav;
