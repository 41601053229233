let BASE_URL = process.env.REACT_APP_BASE_URL;
// let BASE_URL = 'http://localhost:5002/self-assessment-26db7/asia-south1/';
let PSYPACK_URL = process.env.REACT_APP_PSYPACK_URL;

if (process.env.REACT_APP_NODE_ENVIRONMENT === 'staging') {
  BASE_URL = 'https://asia-south1-self-assessment-26db7.cloudfunctions.net/';
  PSYPACK_URL = 'https://test.psypack.com/';
}

export {BASE_URL, PSYPACK_URL};
