import {BASE_URL} from './constants';
import {firestore} from './firebase.helper';

export const makePost = (url, data) =>
  fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const makeGet = (url) =>
  fetch(`${BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const getAssessments = (orgId) => {
  const url = `assessments/${orgId}/get-assessments`;
  return makeGet(url);
};

export const initiateAssessment = (data) => {
  const url = 'assessments/initiate-self-assessment';
  return makePost(url, data);
};

export const getPsychoeducation = (orgId, assessmentId) => {
  const url = `assessments/${orgId}/psychoeducation/${assessmentId}`;
  return makeGet(url);
};

export const getUserAssessmentStatus = (orgId, userAssessmentId) => {
  const url = `assessments/${orgId}/assessment-status/${userAssessmentId}`;
  return makeGet(url);
};

export const getOrgDetails = (orgId) =>
  firestore.collection('org_details').doc(orgId).get();
