import React, {useState, useEffect} from 'react';
import {useLocation, useParams, Link} from 'react-router-dom';
import styles from './complete.module.scss';
import {getPsychoeducation, getOrgDetails} from '../Shared/Helpers/api.helper';
import BackdropLoader from '../Shared/Components/backdrop-loader/BackdropLoader';
import completeBg from '../../assets/complete_bg.svg';
import StatusLoader from './status-loader/StatusLoader';
import Nav from '../Shared/Components/nav/Nav';

export default function Complete() {
  const location = useLocation();
  const {id} = useParams();
  const [psychoeducation, setPsychoeducation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orgDetails, setOrgDetails] = useState({});
  const name = new URLSearchParams(location.search).get('clientName');
  const assessmentId = new URLSearchParams(location.search).get('assessmentId');
  const userAssessmentId = new URLSearchParams(location.search).get(
    'userAssessmentId'
  );

  useEffect(() => {
    getPsychoeducation(id, assessmentId)
      .then((res) => res.json())
      .then((res) => {
        setPsychoeducation(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    getOrgDetails(id)
      .then((res) => res.data())
      .then((res) => {
        if (res) setOrgDetails(res);
      });
  }, [id]);

  return (
    <>
      <Nav orgLogo={orgDetails.icon} />
      <div
        className={styles.container}
        style={{backgroundImage: `url(${completeBg})`}}
      >
        {loading && <BackdropLoader />}
        <div className={styles.complete}>
          <div className={styles.thanks}>
            Thank you {name} for taking the self assessment. Your report has
            been emailed to you. Please remember that these results are NOT
            diagnoses, but we do suggest follow-up with a professional as a best
            next step.
          </div>
        </div>
        <StatusLoader orgId={id} userAssessmentId={userAssessmentId} />
        <div className={styles.complete}>
          <div className={styles.congrats}>
            Congratulations on successfully taking the first step in managing
            your emotional and mental well-being.
          </div>
          <div className={styles.psychoEdu}>
            <div className={styles.title}>Psychoeducation</div>
            <ul className={styles.content}>
              {psychoeducation.map((point) => (
                <li key={point}>{point}</li>
              ))}
            </ul>
          </div>
          <Link className={styles.take_another} to={`/${id}`}>
            Take another assessment
          </Link>
        </div>
      </div>
    </>
  );
}
