import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import Complete from '../Complete/Complete';
import Screening from '../Screening/Screening';
import styles from './home.module.scss';

const Home = () => (
  <div className={styles.container}>
    <Router>
      <Switch>
        {/* <Redirect exact from='/dashboard' to='/dashboard/assessment' /> */}
        <Route exact path='/:id/complete' component={Complete} />
        <Route exact path='/:id' component={Screening} />
      </Switch>
    </Router>
  </div>
);

export default Home;
